export const UserRole = {
  User: 0,
  SuperAdmin: 1,
  Organization: 2,
  Admin: 3,
  Mentor: 4,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'de', name: 'German', direction: 'ltr' },
];

export const adminRoot = '/home';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  role: UserRole.User,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const currentUserStorageKey = '__current_user';
export const profileDataStorageKey = '__profile_data';
export const hackathonDataStorageKey = '__hackathon_data';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const apiUri = 'https://api.smart-natives.de/index.php/api';

export const genderOptions = [
  { label: 'Male', value: 'Male', key: 0 },
  { label: 'Female', value: 'Female', key: 1 },
  { label: 'Other', value: 'Other', key: 2 },
];
export const shirtSizeOptions = [
  { label: 'XXS', value: 'XXS', key: 0 },
  { label: 'XS', value: 'XS', key: 1 },
  { label: 'S', value: 'S', key: 2 },
  { label: 'M', value: 'M', key: 3 },
  { label: 'L', value: 'L', key: 4 },
  { label: 'XL', value: 'XL', key: 5 },
  { label: 'XXL', value: 'XXL ', key: 6 },
];
export const hackthonTypeOptions = [
  { label: 'Online', value: 0, key: 0 },
  { label: 'Blender', value: 1, key: 1 },
  { label: 'Offline', value: 2, key: 2 },
];
export const hackthonApplication = {
  about: [
    {
      id: 'first-and-last-name',
      messageId: 'challenges.about.first-and-last-name',
      value: 1,
      key: 0,
    },
    { id: 'bio', messageId: 'challenges.about.bio', value: 2, key: 1 },
    { id: 'gender', messageId: 'challenges.about.gender', value: 3, key: 2 },
    { id: 'dob', messageId: 'challenges.about.dob', value: 4, key: 3 },
    {
      id: 'tsirt-size',
      messageId: 'challenges.about.tshirt-size',
      value: 5,
      key: 4,
    },
  ],
  contact: [
    {
      id: 'phone-number',
      messageId: 'challenges.contact.phone-number',
      value: 1,
      key: 0,
    },
    {
      id: 'email',
      messageId: 'challenges.contact.email',
      value: 2,
      key: 1,
    },
    {
      id: 'city',
      messageId: 'challenges.contact.city',
      value: 3,
      key: 2,
    },
    {
      id: 'emergency-contact',
      messageId: 'challenges.contact.emergency-contact',
      value: 4,
      key: 3,
    },
  ],
  education: [
    {
      id: 'education-details',
      messageId: 'challenges.education.education-details',
      value: 1,
      key: 0,
    },
  ],
  experience: [
    {
      id: 'domain-expertise',
      messageId: 'challenges.experience.domain-expertise',
      value: 1,
      key: 0,
    },
    {
      id: 'skills',
      messageId: 'challenges.experience.skills',
      value: 2,
      key: 1,
    },
    {
      id: 'resume',
      messageId: 'challenges.experience.resume',
      value: 3,
      key: 2,
    },
    {
      id: 'work-experience',
      messageId: 'challenges.experience.work-experience',
      value: 4,
      key: 3,
    },
  ],
  online_profiles: [
    {
      id: 'github',
      messageId: 'challenges.online-profiles.github',
      value: 1,
      key: 0,
    },
    {
      id: 'dribble',
      messageId: 'challenges.online-profiles.dribble',
      value: 1,
      key: 0,
    },
    {
      id: 'behance',
      messageId: 'challenges.online-profiles.behance',
      value: 1,
      key: 0,
    },
    {
      id: 'stack-overflow"',
      messageId: 'challenges.online-profiles.stack-overflow',
      value: 1,
      key: 0,
    },
    {
      id: 'linkedin',
      messageId: 'challenges.online-profiles.linkedin',
      value: 1,
      key: 0,
    },
    {
      id: 'facebook',
      messageId: 'challenges.online-profiles.facebook',
      value: 1,
      key: 0,
    },
    {
      id: 'twitter',
      messageId: 'challenges.online-profiles.twitter',
      value: 1,
      key: 0,
    },
  ],
};

export const sgdGoals = [
  {
    id: 1,
    name: 'Eliminate Poverty',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 2,
    name: 'Erase Hunger',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 3,
    name: 'Establish Good Health and Well-Being',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 4,
    name: 'Provide Quality Education',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 5,
    name: 'Enforce Gender Equality',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 6,
    name: 'Improve Clean Water and Sanitation',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 7,
    name: 'Grow Affordable and Clean Energy',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 8,
    name: 'Create Decent Work and Economic Growth',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 9,
    name: 'Increase Industry, Innovation, and Infrastructure',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 10,
    name: 'Reduce Inequality',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 11,
    name: 'Mobilize Sustainable Cities and Communities',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 12,
    name: 'Influence Responsible Consumption and Production',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 13,
    name: 'Organize Climate Action',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 14,
    name: 'Develop Life Below Water',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 15,
    name: 'Advance Life On Land',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 16,
    name: 'Guarantee Peace, Justice, and Strong Institutions',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 17,
    name: 'Build Partnerships for the Goals',
    created_at: null,
    updated_at: null,
    icon: null,
  },
];

export const megaTrends = [
  {
    id: 1,
    name: 'Megatrend Gender shift',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 2,
    name: 'Megatrend Health ',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 3,
    name: 'Megatrend globalization',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 4,
    name: 'Megatrend connectivity',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 5,
    name: 'Megatrend customization',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 6,
    name: 'Megatrend mobility',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 7,
    name: 'Megatrend New Work',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 8,
    name: 'Megatrend neo-ecology',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 9,
    name: 'Megatrend Security ',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 10,
    name: 'Megatrend Urbanization ',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 11,
    name: 'Megatrend Silver Society',
    created_at: null,
    updated_at: null,
    icon: null,
  },
  {
    id: 12,
    name: 'Megatrend knowledge culture',
    created_at: null,
    updated_at: null,
    icon: null,
  },
];
