// eslint-disable-next-line import/no-cycle
import {
  GET_ALL_HACKATHONS,
  GET_ALL_HACKATHONS_ERROR,
  GET_ALL_HACKATHONS_SUCCESS,
  GET_HACKATHON_BY_ID,
  GET_HACKATHON_BY_ID_ERROR,
  GET_HACKATHON_BY_ID_SUCCESS,
  UPDATE_CURRENT_HACKATHON,
  UPDATE_CURRENT_HACKATHON_ERROR,
  UPDATE_CURRENT_HACKATHON_SUCCESS,
} from '../actions';

export const getHackathonByID = (accessToken, id) => ({
  type: GET_HACKATHON_BY_ID,
  payload: { accessToken, id },
});
export const getHackathonByIDSuccess = (data) => ({
  type: GET_HACKATHON_BY_ID_SUCCESS,
  payload: { data },
});
export const getHackathonByIDError = (message) => ({
  type: GET_HACKATHON_BY_ID_ERROR,
  payload: { message },
});
export const getAllHackathons = (accessToken, id) => ({
  type: GET_ALL_HACKATHONS,
  payload: { accessToken, id },
});
export const getAllHackathonsSuccess = (data) => ({
  type: GET_ALL_HACKATHONS_SUCCESS,
  payload: { data },
});
export const getAllHackathonsError = (message) => ({
  type: GET_ALL_HACKATHONS_ERROR,
  payload: { message },
});
export const updateCurrentHackathon = (id) => ({
  type: UPDATE_CURRENT_HACKATHON,
  payload: { id },
});
export const updateCurrentHackathonSuccess = (data) => ({
  type: UPDATE_CURRENT_HACKATHON_SUCCESS,
  payload: { data },
});
export const updateCurrentHackathonError = (message) => ({
  type: UPDATE_CURRENT_HACKATHON_ERROR,
  payload: { message },
});
