/* eslint-disable camelcase */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_USER,
  REGISTER_USER,
  VERIFY_OTP,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PROFILE_ADD_DATA,
  PROFILE_UPDATE_DATA,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  verifyOTPError,
  addProfileDataSuccess,
  addProfileDataError,
  
} from './actions';

import { adminRoot, UserRole } from '../../constants/defaultValues';
import { setCurrentUser, setProfileData } from '../../helpers/Utils';
import instance from '../../helpers/axiosInstance';
import AppError from '../../helpers/AppError';

export function* watchAddProfile() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(PROFILE_ADD_DATA, addProfileData);
}

const addProfileDataAsync = async (orgName,fullName, userID, contactNo,about) => {
  const response = await instance({
    method: 'POST',
    url: '/addProfile',
    data: {
      organization_name: orgName,
      representative_name: fullName,
      user_id: userID,
      contact_no: contactNo,
      bio: about,
    },
  });
  const { data } = response;
  return data;
};

const getProfileDataAsync = async (id) => {
  const response = await instance({
    method: 'GET',
    url: `/profile/${id}`,
  });

  return { ...response.data };
};

function* addProfileData({ payload }) {
  const { orgName,fullName, userID, contactNo,about } = payload.profile;
  const { history } = payload;
  try {
    const profileData = yield call(
      addProfileDataAsync,
      orgName,
      fullName,
      userID,
      contactNo,
      about
    );
    if (profileData.success) {
      const { details } = profileData;

      const data = yield call(getProfileDataAsync, userID);
    if (data.success) {
      const { profile } = data;
      setProfileData({ ...profile });
    } else {
      setProfileData();
    }
      yield put(addProfileDataSuccess(details));
      history.push(`${adminRoot}/profile/about`);
    } else {
      yield put(addProfileDataError(profileData.message));
    }
  } catch (error) {
    yield put(addProfileDataError(error));
  }
}









export function* watchupdateProfile() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(PROFILE_UPDATE_DATA, updateProfileData);
}

const updateProfileDataAsync = async (organizationname,
  representativename,
  instagramAcc,
  linkedinAcc,
  contactno,
  address1,
  about) => {
  const response = await instance({
    method: 'POST',
    url: '/updateProfile',
    data: {
      organization_name: organizationname,
      representative_name: representativename,
      contact_no: contactno,
      bio: about,
      address: address1,
      linkedin: linkedinAcc,
      instagram: instagramAcc,
    },
  });
  const { data } = response;
  return data;
};

function* updateProfileData({ payload }) {
  const { organization_name,
    representative_name,
    instagram,
    linkedin,
    contact_no,
    address,
    bio } = payload.profile;

    console.log("sxdcfvg");

    try{const profileData = yield call(
      updateProfileDataAsync,
      organization_name,
    representative_name,
    instagram,
    linkedin,
    contact_no,
    address,
    bio
    );

    if (profileData.success) {
      const { details } = profileData;
      setProfileData(details);
      yield put(addProfileDataSuccess(details));
    } else {
      yield put(addProfileDataError(profileData.message));
    }
  } catch (error) {
    yield put(addProfileDataError(error));
  }
  
}


















export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/login',
    data: { email, password },
    timeout:100000,
  });

  const { data } = response;
  return data;
};



function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (loginUser.success) {
      const { user, access_token } = loginUser.data;
      setCurrentUser({ ...user, access_token });
      if (user.role !== UserRole.Organization) {
        throw new AppError(
          'Please login through Organization Account',
          'Authentication Error'
        );
      }
      yield put(loginUserSuccess(user));

      const data = yield call(getProfileDataAsync, user.id);

      if (data.success) {
        const { profile } = data;
        setProfileData({ ...profile });
      } else {
        setProfileData();
      }

      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.error));
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  email,
  password,
  phone,
  userType = UserRole.Organization
) => {
  console.log(userType);
  const response = await instance({
    method: 'POST',
    url: '/auth/register',
    data: { email, password, phone_number: phone, user_type: userType },
    timeout: 10000
  });
  console.log("rfvtyhu");
  const { data } = response;

  return data;
};

function* registerWithEmailPassword({ payload }) {
  const { email, password, phone } = payload.user;
  const { history } = payload;

  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      phone
    );
    console.log("hello");
    console.log(registerUser);
    if (registerUser.success) {
      window.localStorage.setItem('_token', registerUser.token);
      window.localStorage.setItem('_password', password);
      window.localStorage.setItem('_email', email);
      yield put(registerUserSuccess(registerUser.message));
      history.push('/user/verify-otp');
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  setCurrentUser(null);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser(null);
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/forgotPassword',
    data: { email },
  });

  const { data } = response;
  return data;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  const {history} = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    console.log(forgotPasswordStatus);
    if (forgotPasswordStatus.success) {
      window.localStorage.setItem('_token', forgotPasswordStatus.token);
      console.log(forgotPasswordStatus.token);
      yield put(forgotPasswordSuccess('success'));
      history.push('/user/reset-password');
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (newPassword,OTP) => {
  const response = await instance({
    method: 'POST',
    url: '/auth/change-password',
    data: {
      password: newPassword,
      otp: OTP,
    },
  });

  const { data } = response;
  return data;
};

function* resetPassword({ payload }) {
  const { newPassword, OTP } = payload;
  const { history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      newPassword,
      OTP
    );
    if (resetPasswordStatus.success) {
      yield put(resetPasswordSuccess(resetPasswordStatus.message));
      console.log("heyy");
      history.push('/user/login');
    } else {
      yield put(resetPasswordError("Reset Password Error"));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchVerifyOTP() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(VERIFY_OTP, verifyOTP);
}

const verifyOTPAsync = async (OTP) => {
  const load = {
    otp: OTP,
    verify_token: window.localStorage.getItem('_token'),
    password: window.localStorage.getItem('_password'),
  };

  const response = await instance({
    method: 'POST',
    url: '/auth/verifyOtp',
    data: load,
  });

  const { data } = response;
  return data;
};

function* verifyOTP({ payload }) {
  const { OTP } = payload;
  const { history } = payload;

  try {
    const verifyOTPStatus = yield call(verifyOTPAsync, OTP);

    if (verifyOTPStatus.success) {
      const userData = verifyOTPStatus.data.user;
      window.localStorage.removeItem('_token');
      window.localStorage.removeItem('_password');
      setCurrentUser(userData);
      yield put(loginUserSuccess(userData));
      history.push('/user/complete-registration');
    } else {
      yield put(verifyOTPError(verifyOTPStatus.message));
    }
  } catch (error) {
    yield put(verifyOTPError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchVerifyOTP),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchAddProfile),
    fork(watchupdateProfile),
  ]);
}
