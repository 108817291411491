// eslint-disable-next-line import/no-cycle
import {
  GET_FAQ,
  GET_FAQ_ERROR,
  GET_FAQ_SUCCESS,
  GET_JUDGE,
  GET_JUDGE_ERROR,
  GET_JUDGE_SUCCESS,
  GET_PRIZE,
  GET_PRIZE_ERROR,
  GET_PRIZE_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_ERROR,
  GET_QUESTION_SUCCESS,
  GET_SPONSOR,
  GET_SPONSOR_ERROR,
  GET_SPONSOR_SUCCESS,
} from '../actions';

export const getFAQ = (accessToken, id) => ({
  type: GET_FAQ,
  payload: { accessToken, id },
});
export const getFAQSuccess = (data) => ({
  type: GET_FAQ_SUCCESS,
  payload: { data },
});
export const getFAQError = (message) => ({
  type: GET_FAQ_ERROR,
  payload: { message },
});
export const getJudge = (accessToken, id) => ({
  type: GET_JUDGE,
  payload: { accessToken, id },
});
export const getJudgeSuccess = (data) => ({
  type: GET_JUDGE_SUCCESS,
  payload: { data },
});
export const getJudgeError = (message) => ({
  type: GET_JUDGE_ERROR,
  payload: { message },
});
export const getQuestion = (accessToken, id) => ({
  type: GET_QUESTION,
  payload: { accessToken, id },
});
export const getQuestionSuccess = (data) => ({
  type: GET_QUESTION_SUCCESS,
  payload: { data },
});
export const getQuestionError = (message) => ({
  type: GET_QUESTION_ERROR,
  payload: { message },
});
export const getSponsors = (accessToken, id) => ({
  type: GET_SPONSOR,
  payload: { accessToken, id },
});
export const getSponsorsSuccess = (data) => ({
  type: GET_SPONSOR_SUCCESS,
  payload: { data },
});
export const getSponsorsError = (message) => ({
  type: GET_SPONSOR_ERROR,
  payload: { message },
});
export const getPrizes = (accessToken, id) => ({
  type: GET_PRIZE,
  payload: { accessToken, id },
});
export const getPrizesSuccess = (data) => ({
  type: GET_PRIZE_SUCCESS,
  payload: { data },
});
export const getPrizesError = (message) => ({
  type: GET_PRIZE_ERROR,
  payload: { message },
});
