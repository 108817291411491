import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import applicationData from './application/reducer';
import hackathonData from './hackathon/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  applicationData,
  hackathonData,
});

export default reducers;
