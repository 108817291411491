import {
  GET_FAQ,
  GET_FAQ_ERROR,
  GET_FAQ_SUCCESS,
  GET_JUDGE,
  GET_JUDGE_ERROR,
  GET_JUDGE_SUCCESS,
  GET_PRIZE,
  GET_PRIZE_ERROR,
  GET_PRIZE_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_ERROR,
  GET_QUESTION_SUCCESS,
  GET_SPONSOR,
  GET_SPONSOR_ERROR,
  GET_SPONSOR_SUCCESS,
} from '../actions';

const INIT_STATE = {
  faqs: [],
  judges: [],
  questions: [],
  sponsors: [],
  prizes: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAQ:
      return { ...state, loading: true };
    case GET_FAQ_SUCCESS:
      return { ...state, loading: false, error: '', faqs: action.payload.data };
    case GET_FAQ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_JUDGE:
      return { ...state, loading: true };
    case GET_JUDGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        judges: action.payload.data,
      };
    case GET_JUDGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_QUESTION:
      return { ...state, loading: false };
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        questions: action.payload.data,
      };
    case GET_QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_PRIZE:
      return { ...state, loading: true };
    case GET_PRIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        prizes: action.payload.data,
      };
    case GET_PRIZE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case GET_SPONSOR:
      return { ...state, loading: true };
    case GET_SPONSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        sponsors: action.payload.data,
      };
    case GET_SPONSOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
