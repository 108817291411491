import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import instance from '../../helpers/axiosInstance';
import { log } from '../../helpers/Utils';
import { GET_FAQ, GET_JUDGE, GET_PRIZE, GET_SPONSOR } from '../actions';
import {
  getFAQError,
  getFAQSuccess,
  getJudgeError,
  getJudgeSuccess,
  getSponsorsError,
  getSponsorsSuccess,
  getPrizesSuccess,
  getPrizesError,
} from './actions';

const getFAQAsync = async (accessToken, id) => {
  const response = await instance({
    method: 'GET',
    url: `/getFaqs/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const getSponsorsAsync = async (accessToken, id) => {
  const response = await instance({
    method: 'GET',
    url: `/getSponsors/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const getJudgesAsync = async (accessToken, id) => {
  const response = await instance({
    method: 'GET',
    url: `/getJudges/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const getPrizeAsync = async (accessToken, id) => {
  const response = await instance({
    method: 'GET',
    url: `/getPrizes/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

function* getFAQ({ payload }) {
  const { accessToken, id } = payload;

  try {
    const faqsData = yield call(getFAQAsync, accessToken, id);

    if (faqsData.success) {
      yield put(getFAQSuccess(faqsData.faqs));
    } else {
      yield put(getFAQError('Error getting FAQ.'));
    }
  } catch (err) {
    log(err);
    yield put(getFAQError('Error getting FAQ.'));
  }
}

function* getSponsors({ payload }) {
  const { accessToken, id } = payload;

  try {
    const sponsorData = yield call(getSponsorsAsync, accessToken, id);

    if (sponsorData.success) {
      yield put(getSponsorsSuccess(sponsorData.judges));
    } else {
      yield put(getSponsorsError('Error getting Sponsors.'));
    }
  } catch (err) {
    log(err);
    yield put(getSponsorsError('Error getting Sponsors.'));
  }
}

function* getJudges({ payload }) {
  const { accessToken, id } = payload;
  log(accessToken);

  try {
    const judgesData = yield call(getJudgesAsync, accessToken, id);

    if (judgesData.success) {
      yield put(getJudgeSuccess(judgesData.judges));
    } else {
      yield put(getJudgeError('Error getting Judges.'));
    }
  } catch (err) {
    log(err);
    yield put(getJudgeError('Error getting Judges.'));
  }
}

function* getPrize({ payload }) {
  const { accessToken, id } = payload;

  try {
    const prizeData = yield call(getPrizeAsync, accessToken, id);

    if (prizeData.success) {
      yield put(getPrizesSuccess(prizeData.Prizes));
    } else {
      yield put(getPrizesError('Error getting Prize.'));
    }
  } catch (err) {
    log(err);
    yield put(getPrizesError('Error getting Prize.'));
  }
}

export function* watchGetFAQ() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_FAQ, getFAQ);
}

export function* watchGetSponsors() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_SPONSOR, getSponsors);
}

export function* watchGetJudges() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_JUDGE, getJudges);
}

export function* watchGetPrize() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PRIZE, getPrize);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetFAQ),
    fork(watchGetSponsors),
    fork(watchGetJudges),
    fork(watchGetPrize),
  ]);
}
