import Proptypes from 'prop-types';

class AppError extends Error {
  constructor(message, name) {
    super(message);
    this.name = name || this.constructor.name;
  }
}

AppError.propTypes = {
  message: Proptypes.string.isRequired,
  name: Proptypes.string,
};

export default AppError;
