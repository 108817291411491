import {
  GET_ALL_HACKATHONS,
  GET_ALL_HACKATHONS_ERROR,
  GET_ALL_HACKATHONS_SUCCESS,
  GET_HACKATHON_BY_ID,
  GET_HACKATHON_BY_ID_ERROR,
  GET_HACKATHON_BY_ID_SUCCESS,
  UPDATE_CURRENT_HACKATHON,
  UPDATE_CURRENT_HACKATHON_ERROR,
  UPDATE_CURRENT_HACKATHON_SUCCESS,
} from '../actions';

const INIT_STATE = {
  organizerHackathons: [],
  hackathonDetails: {
    application: {
      about: [],
      contact: [],
      education: [],
      experience: [],
      online_profiles: [],
    },
  },
  currentHackathon: {
    organizer_id: null,
    id: null,
    name: '',
    application_end: '',
    application_start: '',
    aproximate_participants: 0,
    contact_email: '',
    created_at: '',
    hackathon_cover_img: '',
    hackathon_date: '',
    hackathon_logo: '1',
    hackathon_start: '',
    hackathon_type_id: 1,
    hackathon_website: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    link_to_code_of_control: '',
    max_team_size: 0,
    mega_trends: null,
    portfolio_url: '',
    results_date: '',
    sdg_goals: null,
    status: 0,
    submission_date: '',
    submit_status: 0,
    tagline: '',
    theme_color_code: null,
    timezone: null,
    twitter: '',
    venue: null,
    application: {
      about: [],
      contact: [],
      education: [],
      experience: [],
      online_profiles: [],
    },
  },
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HACKATHON_BY_ID:
      return { ...state, loading: true };
    case GET_HACKATHON_BY_ID_SUCCESS:
      return {
        ...state,
        hackathonDetails: action.payload.data,
        loading: false,
      };
    case GET_HACKATHON_BY_ID_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    case GET_ALL_HACKATHONS:
      return { ...state, loading: true };
    case GET_ALL_HACKATHONS_SUCCESS:
      return {
        ...state,
        organizerHackathons: action.payload.data,
        loading: false,
      };
    case GET_ALL_HACKATHONS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case UPDATE_CURRENT_HACKATHON:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CURRENT_HACKATHON_SUCCESS:
      return {
        ...state,
        currentHackathon: action.payload.data,
        loading: false,
      };
    case UPDATE_CURRENT_HACKATHON_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    default:
      return { ...state };
  }
};
