import axios from 'axios';
import { apiUri } from '../constants/defaultValues';

const instance = axios.create({
  baseURL: apiUri,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

instance.interceptors.request.use((request) => {
  console.log(
    '>>> Time : ',
    new Date(Date.now()).toLocaleTimeString(),
    '\n',
    'Starting Request',
    JSON.stringify(request, null, 2)
  );

  return request;
});

instance.interceptors.response.use((response) => {
  console.log(
    '>>> Time : ',
    new Date(Date.now()).toLocaleTimeString(),
    '\n',
    'Response: ',
    JSON.stringify(response, null, 2)
  );

  return response;
});

instance.defaults.timeout = 3000;

export default instance;
