import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { hackathonDataStorageKey } from '../../constants/defaultValues';
import instance from '../../helpers/axiosInstance';
import { getHackathonInfo, log } from '../../helpers/Utils';
import {
  getAllHackathonsError,
  getAllHackathonsSuccess,
  GET_ALL_HACKATHONS,
  GET_HACKATHON_BY_ID,
  UPDATE_CURRENT_HACKATHON,
} from '../actions';

import {
  getHackathonByIDError,
  getHackathonByIDSuccess,
  updateCurrentHackathonError,
  updateCurrentHackathonSuccess,
} from './actions';

const getHackathonDetailsAsync = async (accessToken, id) => {
  const response = await instance({
    url: `/getdetails/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const getHackathonsAsync = async (accessToken, id) => {
  const response = await instance({
    url: `/getHackathonList/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return { ...response.data };
};

const updateCurrentHackathonAsync = (id) => {
  return { ...getHackathonInfo(id) };
};

function* getAllHackathons({ payload }) {
  const { accessToken, id } = payload;

  try {
    const hackathons = yield call(getHackathonsAsync, accessToken, id);

    if (hackathons.success) {
      localStorage.setItem(
        hackathonDataStorageKey,
        JSON.stringify(hackathons.data)
      );
      yield put(getAllHackathonsSuccess(hackathons.data));
    } else {
      yield put(getAllHackathonsError('Error getting Hackathons.'));
    }
  } catch (err) {
    log(err);
    yield put(getAllHackathonsError('Error getting Hackathons.'));
  }
}

function* updateCurrentHackathon({ payload }) {
  const { id } = payload;

  try {
    const hackathon = yield call(updateCurrentHackathonAsync, id);
    if (hackathon) {
      yield put(updateCurrentHackathonSuccess(hackathon));
    } else {
      yield put(updateCurrentHackathonError('Error getting Hackathons.'));
    }
  } catch (err) {
    log(err);
    yield put(getAllHackathonsError('Error getting Hackathons.'));
  }
}

function* getHackathonByID({ payload }) {
  const { accessToken, id } = payload;

  try {
    const details = yield call(getHackathonDetailsAsync, accessToken, id);
    if (details.success) {
      yield put(getHackathonByIDSuccess(details.getdetails));
    } else {
      yield put(getHackathonByIDError('Error getting Hackathon'));
    }
  } catch (err) {
    log(err);
  }
}

export function* watchGetOrganizerHackathons() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_HACKATHONS, getAllHackathons);
}
export function* watchUpdateCurrentHackathon() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_CURRENT_HACKATHON, updateCurrentHackathon);
}
export function* watchGetHackathon() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_HACKATHON_BY_ID, getHackathonByID);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetOrganizerHackathons),
    fork(watchUpdateCurrentHackathon),
    fork(watchGetHackathon),
  ]);
}
